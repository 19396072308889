var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      ref: "form",
      attrs: { model: _vm.form, rules: _vm.rules, "label-width": "96px" },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "wrap",
          attrs: { id: "wrap" },
        },
        [
          _c(
            "topOperatingButton",
            {
              attrs: {
                disabled: _vm.disabled,
                showAudit: _vm.showAudit,
                isAddBtn: _vm.showAdd,
                id: "topOperatingButton",
              },
              on: {
                submitForm: _vm.submitForm,
                addBill: _vm.addBill,
                auditBill: _vm.auditBill,
                handleAdd: _vm.handleAdd,
                getQuit: _vm.getQuit,
              },
            },
            [
              _c(
                "template",
                { slot: "specialDiyBtn" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "mini", disabled: _vm.disabledIntroduce },
                      on: { click: _vm.selectBills },
                    },
                    [_vm._v("引入源单 ")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "mini", disabled: _vm.single },
                      on: {
                        click: function ($event) {
                          return _vm.auditBill("终止")
                        },
                      },
                    },
                    [_vm._v("终止 ")]
                  ),
                ],
                1
              ),
            ],
            2
          ),
          _c(
            "cardTitleCom",
            {
              attrs: {
                cardTitle: "基本信息",
                billStatus: _vm.form.billStatus,
                id: "basicInformation",
              },
            },
            [
              _c("template", { slot: "cardContent" }, [
                _c(
                  "div",
                  { staticClass: "x-f marT10" },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "单据编号", prop: "billNo" } },
                      [
                        _c("el-input", {
                          staticClass: "inputWidth",
                          attrs: {
                            size: "mini",
                            disabled: true,
                            placeholder: "单据编号",
                          },
                          model: {
                            value: _vm.form.billNo,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "billNo", $$v)
                            },
                            expression: "form.billNo",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "单据日期", prop: "billDate" } },
                      [
                        _c("el-date-picker", {
                          staticStyle: { width: "250px" },
                          attrs: {
                            size: "mini",
                            type: "date",
                            placeholder: "单据日期",
                            disabled: _vm.disabled,
                            "value-format": "yyyy-MM-dd",
                          },
                          model: {
                            value: _vm.form.billDate,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "billDate", $$v)
                            },
                            expression: "form.billDate",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "调入仓库", prop: "inStoreId" } },
                      [
                        _c("SelectRemote", {
                          staticStyle: { width: "250px" },
                          attrs: {
                            option: _vm.$select({
                              key: "listStore",
                              option: {
                                option: {
                                  disabled: _vm.disabled,
                                  remoteBody: { storeTypes: [1, 2] },
                                },
                              },
                            }).option,
                          },
                          model: {
                            value: _vm.form.inStoreId,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "inStoreId", $$v)
                            },
                            expression: "form.inStoreId",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "调出仓库", prop: "outStoreId" } },
                      [
                        _c("SelectRemote", {
                          staticStyle: { width: "250px" },
                          attrs: {
                            disabled: true,
                            option: _vm.$select({
                              key: "listStore",
                              option: {
                                option: { disabled: _vm.disabled },
                              },
                            }).option,
                          },
                          model: {
                            value: _vm.form.outStoreId,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "outStoreId", $$v)
                            },
                            expression: "form.outStoreId",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "x-f marT10 marB10" },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "业务类型", prop: "billCustomType" } },
                      [
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "250px" },
                            attrs: {
                              disabled: true,
                              size: "mini",
                              placeholder: "请选择业务类型",
                            },
                            model: {
                              value: _vm.form.billCustomType,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "billCustomType", $$v)
                              },
                              expression: "form.billCustomType",
                            },
                          },
                          [
                            _c("el-option", {
                              attrs: { label: "同价调拨", value: 1 },
                            }),
                            _c("el-option", {
                              attrs: { label: "异价调拨", value: 2 },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "单据备注", prop: "remark" } },
                      [
                        _c("el-input", {
                          staticClass: "inputWidth",
                          attrs: {
                            disabled: _vm.disabled,
                            type: "textarea",
                            placeholder: "备注长度介于 1 和 80 字符之间",
                            maxlength: "80",
                            autosize: { minRows: 1, maxRows: 1 },
                          },
                          model: {
                            value: _vm.form.billRemark,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "billRemark", $$v)
                            },
                            expression: "form.billRemark",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
          _c(
            "cardTitleCom",
            { staticClass: "headGoodsTable", attrs: { cardTitle: "商品信息" } },
            [
              _c("template", { slot: "cardContent" }, [
                _c(
                  "div",
                  { staticClass: "goodsMessage" },
                  [
                    _c(
                      "el-table",
                      {
                        ref: "multipleTable",
                        attrs: {
                          data: _vm.form.details,
                          border: "",
                          "show-summary": "",
                          "summary-method": _vm.getSummaries,
                          "max-height": _vm.tableHeight,
                          height: _vm.tableHeight,
                        },
                      },
                      [
                        _c("el-table-column", {
                          attrs: {
                            align: "center",
                            label: _vm.tableCellLabel,
                            width: "80",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  !scope.row.hoverRow
                                    ? _c("div", [
                                        _vm._v(_vm._s(scope.$index + 1)),
                                      ])
                                    : _vm._e(),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "商品编码",
                            width: "160",
                            align: "center",
                            prop: "goodsNo",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("el-input", {
                                    attrs: { disabled: true, maxlength: "20" },
                                    nativeOn: {
                                      keyup: function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        ) {
                                          return null
                                        }
                                        return _vm.keyup(
                                          "goodsNo",
                                          $event,
                                          scope.$index
                                        )
                                      },
                                    },
                                    model: {
                                      value: scope.row.goodsNo,
                                      callback: function ($$v) {
                                        _vm.$set(scope.row, "goodsNo", $$v)
                                      },
                                      expression: "scope.row.goodsNo",
                                    },
                                  }),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "商品名称",
                            align: "center",
                            prop: "goodsName",
                            width: "120",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "条码",
                            width: "160",
                            align: "center",
                            prop: "barcode",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "规格",
                            align: "center",
                            prop: "goodsSpec",
                            width: "120",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "el-form-item",
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          attrs: { disabled: true },
                                          on: {
                                            change: function ($event) {
                                              return _vm.unitSalePriceChange(
                                                $event,
                                                scope.row
                                              )
                                            },
                                          },
                                          model: {
                                            value: scope.row.unitId,
                                            callback: function ($$v) {
                                              _vm.$set(scope.row, "unitId", $$v)
                                            },
                                            expression: "scope.row.unitId",
                                          },
                                        },
                                        _vm._l(
                                          scope.row.units,
                                          function (item) {
                                            return _c("el-option", {
                                              key: item.unitId,
                                              attrs: {
                                                label: item.goodsSpec,
                                                value: item.unitId,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c(
                          "el-table-column",
                          {
                            attrs: {
                              label: "单位",
                              align: "center",
                              prop: "unitName",
                              width: "120",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          prop:
                                            "details." +
                                            scope.$index +
                                            ".unitId",
                                          rules: _vm.rules["details.unitId"],
                                        },
                                      },
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            attrs: { disabled: true },
                                            on: {
                                              change: function ($event) {
                                                return _vm.unitSalePriceChange(
                                                  $event,
                                                  scope.row
                                                )
                                              },
                                            },
                                            model: {
                                              value: scope.row.unitId,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  scope.row,
                                                  "unitId",
                                                  $$v
                                                )
                                              },
                                              expression: "scope.row.unitId",
                                            },
                                          },
                                          _vm._l(
                                            scope.row.units,
                                            function (item) {
                                              return _c("el-option", {
                                                key: item.unitId,
                                                attrs: {
                                                  label: item.unitName,
                                                  value: item.unitId,
                                                },
                                              })
                                            }
                                          ),
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ]),
                          },
                          [
                            _c("template", { slot: "header" }, [
                              _c("i", { staticStyle: { color: "#ff4949" } }, [
                                _vm._v("* "),
                              ]),
                              _c("span", [_vm._v("单位")]),
                            ]),
                          ],
                          2
                        ),
                        _c("el-table-column", {
                          attrs: {
                            label: "调出数量",
                            align: "center",
                            "show-overflow-tooltip": "",
                            width: "120",
                            prop: "importUnitQty",
                          },
                        }),
                        _c(
                          "el-table-column",
                          {
                            attrs: {
                              label: "调入数量",
                              align: "center",
                              width: "120",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          prop:
                                            "details." +
                                            scope.$index +
                                            ".unitQty",
                                          rules: _vm.rules["details.unitQty"],
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            oninput:
                                              "value=value.replace(/[^\\d.]/g, '').replace(/\\.{2,}/g, '.').replace('.', '$#$').replace(/\\./g, '').replace('$#$', '.').replace(/^([0-9-, -]\\d*\\.?\\d{0,8})?.*$/,'$1').replace(/^\\./g, '').replace(/^0+(?!\\.|$)/g, ''),value = Number(value) >= 9999999 ? 9999999 : value",
                                            disabled: _vm.disabled,
                                            sunyunFormat: function (v) {
                                              return _vm.$syInput(1, v)
                                            },
                                          },
                                          on: {
                                            blur: function ($event) {
                                              return _vm.onkeyupUnitQty(
                                                scope.row
                                              )
                                            },
                                          },
                                          model: {
                                            value: scope.row.unitQty,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                scope.row,
                                                "unitQty",
                                                $$v
                                              )
                                            },
                                            expression: "scope.row.unitQty",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ]),
                          },
                          [
                            _c("template", { slot: "header" }, [
                              _c("i", { staticStyle: { color: "#ff4949" } }, [
                                _vm._v("* "),
                              ]),
                              _c("span", [_vm._v("调入数量")]),
                            ]),
                          ],
                          2
                        ),
                        _vm.form.billCustomType == 2
                          ? _c(
                              "el-table-column",
                              {
                                attrs: {
                                  label: "调拨单价金额",
                                  align: "center",
                                  "min-width": "150",
                                },
                              },
                              [
                                _c("el-table-column", {
                                  attrs: {
                                    label: "调拨单价(元)",
                                    align: "center",
                                    width: "120",
                                    prop: "unitPrice",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  prop:
                                                    "details." +
                                                    scope.$index +
                                                    ".unitPrice",
                                                  rules:
                                                    _vm.rules[
                                                      "details.unitPrice"
                                                    ],
                                                },
                                              },
                                              [
                                                _c("el-input", {
                                                  attrs: {
                                                    type: "number",
                                                    disabled: true,
                                                    maxlength: "7",
                                                    sunyunFormat: function (v) {
                                                      return _vm.$syInput(2, v)
                                                    },
                                                  },
                                                  on: {
                                                    input: function ($event) {
                                                      return _vm.limitInputlength(
                                                        scope.row.unitPrice,
                                                        scope.row,
                                                        "unitPrice",
                                                        true
                                                      )
                                                    },
                                                  },
                                                  nativeOn: {
                                                    keyup: function ($event) {
                                                      return _vm.onkeyupUnitQty(
                                                        scope.row
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value: scope.row.unitPrice,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        scope.row,
                                                        "unitPrice",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "scope.row.unitPrice",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    1676977506
                                  ),
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    label: "调拨金额(元)",
                                    align: "center",
                                    width: "120",
                                    prop: "unitMoney",
                                    formatter: function (row) {
                                      return _vm.$syInput(3, row.unitMoney)
                                    },
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "el-table-column",
                          {
                            attrs: {
                              label: "进价金额",
                              align: "center",
                              "min-width": "150",
                            },
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                label: "单位进价(元)",
                                align: "center",
                                width: "120",
                                prop: "unitPurPrice",
                                formatter: function (row) {
                                  return _vm.$syInput(2, row.unitPurPrice)
                                },
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "进价金额(元)",
                                align: "center",
                                width: "120",
                                prop: "unitPurMoney",
                                formatter: function (row) {
                                  return _vm.$syInput(3, row.unitPurMoney)
                                },
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-table-column",
                          {
                            attrs: {
                              label: "售价金额",
                              align: "center",
                              "min-width": "150",
                            },
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                label: "单位售价(元)",
                                align: "center",
                                width: "120",
                                prop: "unitSalePrice",
                                formatter: function (row) {
                                  return _vm.$syInput(2, row.unitSalePrice)
                                },
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "售价金额(元)",
                                align: "center",
                                width: "120",
                                prop: "unitSaleMoney",
                                formatter: function (row) {
                                  return _vm.$syInput(2, row.unitSaleMoney)
                                },
                              },
                            }),
                          ],
                          1
                        ),
                        _c("el-table-column", {
                          attrs: {
                            label: "备注",
                            align: "center",
                            prop: "remark",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("el-input", {
                                    attrs: { disabled: true, maxlength: "80" },
                                    model: {
                                      value: scope.row.remark,
                                      callback: function ($$v) {
                                        _vm.$set(scope.row, "remark", $$v)
                                      },
                                      expression: "scope.row.remark",
                                    },
                                  }),
                                ]
                              },
                            },
                          ]),
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
          _c("SelectGoods", {
            ref: "selectGoods",
            attrs: { OpenGoods: _vm.OpenGoods, queryCode: _vm.queryCode },
            on: {
              "update:OpenGoods": function ($event) {
                _vm.OpenGoods = $event
              },
              "update:open-goods": function ($event) {
                _vm.OpenGoods = $event
              },
              getGoodS: _vm.getGoodS,
            },
          }),
          _c("shopBills", {
            ref: "shopBills",
            attrs: {
              openBills: _vm.openBills,
              ishead: _vm.ishead,
              orderTypes: "140305",
              moveAffirmObj: _vm.moveAffirmObj,
              detailList: _vm.form.details,
            },
            on: {
              updateOpenBills: _vm.updateOpenBills,
              shopBillsDate: _vm.shopBillsDate,
              IntroductionStatus: _vm.IntroductionStatus,
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }