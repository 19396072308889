<template>
  <!-- 门店销售订单 -->
  <div class="order-detail">
    <detail :billType="billType" :isEnableSale="true">
      <!-- 插槽 -->
      <template #tableSpecial>
        <el-table-column label="价格方案" align="center" prop="priceCaseName">
        </el-table-column>
      </template>
    </detail>
  </div>
</template>

<script>
import detail from "@/views/components/bill/detail-layout.vue";
export default {
  name: "shopClientSaleOrderDetail",
  components: { detail },
  data() {
    return {
      billType: "140313",
    };
  },
};
</script>
