<template>
  <MaterialShopOrder />
</template>

<script>
import MaterialShopOrder from './components'
export default {
  name: 'materialOrder',
  components: { MaterialShopOrder }
}
</script>
