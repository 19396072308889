<template>
  <div>
    <move-in-detail :billNo="type" ref="moveInDetail" />
  </div>
</template>

<script>
import MoveInDetail from '@/views/components/shop/MoveInDetail.vue'
export default {
  name: 'ScrapDetail',
  components: { MoveInDetail },
  beforeRouteEnter (to, from, next) {
    next(async vm => {
      if(to.query.billId){
        console.log('5555',vm.$refs.moveInDetail)
        vm.$refs.moveInDetail.form.billId = to.query.billId
        vm.$refs.moveInDetail.form.inStoreId = to.query.inStoreId
        vm.$refs.moveInDetail.form.outStoreId = to.query.outStoreId
        vm.$refs.moveInDetail.shopBillsDate()
        vm.$refs.moveInDetail.IntroductionStatus(true)
      }
    })
  },
  data () {
    return {
      type: '140305'
    }
  }
}
</script>

<style></style>
