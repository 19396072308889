<template>
  <ShopMoveOut />
</template>

<script>
import ShopMoveOut from './components'
export default {
  name: 'shopMoveOut',
  components: { ShopMoveOut }
}
</script>
